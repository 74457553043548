import React from 'react';
// import BpmnJS from 'bpmn-js/lib/Modeler';
import { Box } from '@material-ui/core';
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css';
import MuiButton from '../../../UI/MuiButton';
import X2JS from 'x2js';
import HttpDatastore from 'rollun-ts-datastore';
import { BpmnEntity } from './types';
import { BpmnDiagramDialog } from './BpmnDiagramDialog';
import { httpErrorHandlerPromised } from '../../../utils/common.utils';
import BpmnColorPickerModule from 'bpmn-js-color-picker';

function onError(err: any) {
  console.error('failed to render diagram', err);
}

function onShown(warnings: string) {
  console.log('diagram shown', warnings);
}

const x2js = new X2JS();

interface IProps {
  diagramXML: string;
  diagramDSEntity: BpmnEntity | null;
  datastore: HttpDatastore;
}

interface IState {
  isDialogOpen: boolean;
  diagramXML?: string;
  fileName: string;
  updateExisting: boolean;
  result:
    | null
    | {
        success: true;
        type: 'created' | 'updated';
      }
    | {
        success: false;
        message: string;
      };
}

export class BpmnDiagram extends React.Component<IProps, IState> {
  containerRef: React.RefObject<any>;
  bpmnModeler: any;
  constructor(props: any) {
    super(props);

    const fileName = this.props.diagramDSEntity
      ? this.props.diagramDSEntity.file_name
      : '';

    this.state = {
      isDialogOpen: false,
      fileName,
      updateExisting: false,
      result: null,
    };

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    const { diagramXML } = this.props;

    void this.setupModeler(diagramXML);
  }

  componentWillUnmount() {
    this.bpmnModeler.destroy();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const { props, state } = this;

    const currentXML = props.diagramXML || state.diagramXML;

    const previousXML = prevProps.diagramXML || prevState.diagramXML;

    if (currentXML && currentXML !== previousXML) {
      return this.displayDiagram(currentXML);
    }
  }

  async setupModeler(diagramXML: string) {
    const container = this.containerRef.current;

    const exported = await import('bpmn-js/lib/Modeler');
    const BpmnJS = exported.default;

    this.bpmnModeler = new BpmnJS({
      container,
      keyboard: { bindTo: document },
      additionalModules: [BpmnColorPickerModule],
    });

    this.bpmnModeler.on('import.done', (event: any) => {
      const { error, warnings } = event;

      if (error) {
        return onError(error);
      }

      this.bpmnModeler.get('canvas').zoom('fit-viewport');

      return onShown(warnings);
    });

    if (diagramXML) {
      return this.displayDiagram(diagramXML);
    }
  }

  saveSVG() {
    this.bpmnModeler.saveSVG().then(({ svg }: any) => {
      const svgBlob = new Blob([svg], {
        type: 'image/svg+xml',
      });

      const fileName =
        (this.state.fileName || Math.random().toString().substring(7)) + '.svg';

      const downloadLink = document.createElement('a');
      downloadLink.download = fileName;
      downloadLink.innerHTML = 'Get BPMN SVG';
      downloadLink.href = window.URL.createObjectURL(svgBlob);
      downloadLink.onclick = function (event: any) {
        document.body.removeChild(event.target);
      };
      downloadLink.style.visibility = 'hidden';
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  }

  saveXML() {
    this.bpmnModeler.saveXML().then(({ xml }: any) => {
      const xmlBlob = new Blob([xml], {
        type: 'application/xml',
      });

      const reader = new FileReader();
      reader.onload = () => {
        const fileName =
          (this.state.fileName || Math.random().toString().substring(7)) +
          '.bpmn';

        const downloadLink = document.createElement('a');
        downloadLink.download = fileName;
        downloadLink.innerHTML = 'Get BPMN XML';
        downloadLink.href = URL.createObjectURL(xmlBlob);
        downloadLink.onclick = function (event: any) {
          document.body.removeChild(event.target);
        };
        downloadLink.style.visibility = 'hidden';
        document.body.appendChild(downloadLink);
        downloadLink.click();
      };
      reader.readAsText(xmlBlob);
    });
  }

  async uploadToDatastore() {
    try {
      const { datastore, diagramDSEntity } = this.props;
      const { xml } = await this.bpmnModeler.saveXML();
      const { fileName, updateExisting } = this.state;

      const payload = {
        file: x2js.xml2js(xml),
        file_name: fileName,
        id:
          updateExisting && diagramDSEntity
            ? diagramDSEntity.id
            : Math.random().toString().substring(7),
      };

      console.log('payload', {
        payload,
        updateExisting,
        diagramDSEntity,
        fileName,
      });

      const result =
        updateExisting && diagramDSEntity
          ? await datastore.update(payload)
          : await datastore.create(payload);

      console.log('result', result);
      this.setState({
        result: {
          success: true,
          type: updateExisting && diagramDSEntity ? 'updated' : 'created',
        },
      });
    } catch (err) {
      this.setState({
        result: {
          success: false,
          message: (await httpErrorHandlerPromised(err)).text,
        },
      });
    }
  }

  displayDiagram(diagramXML: any) {
    this.bpmnModeler.importXML(diagramXML);
  }

  handleOpenDialog = () => {
    this.setState({ isDialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ isDialogOpen: false, updateExisting: false });
  };

  handleFileNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ fileName: event.target.value });
  };

  handleUpdateExistingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ updateExisting: event.target.checked });
  };

  handleUpload = () => {
    this.uploadToDatastore();
  };

  render() {
    const { isDialogOpen, fileName, updateExisting } = this.state;
    const { diagramDSEntity } = this.props;
    const canUpdateExisting = diagramDSEntity !== null;

    return (
      <Box
        style={{
          marginTop: '10px',
          width: '100%',
          height: '100%',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
          }}
        >
          <MuiButton onClick={() => this.saveXML()}>Save xml local</MuiButton>
          <MuiButton onClick={() => this.saveSVG()}>Save svg local</MuiButton>
          <MuiButton onClick={() => this.handleOpenDialog()}>
            Upload to datastore
          </MuiButton>
        </Box>

        <BpmnDiagramDialog
          isDialogOpen={isDialogOpen}
          fileName={fileName}
          updateExisting={updateExisting}
          canUpdateExisting={canUpdateExisting}
          handleUpdateExistingChange={this.handleUpdateExistingChange}
          handleFileNameChange={this.handleFileNameChange}
          handleUpload={this.handleUpload}
          handleCloseDialog={this.handleCloseDialog}
          isDiagramDsEntity={!!this.props.diagramDSEntity}
          uploadResult={this.state.result}
        />

        <div
          className="react-bpmn-diagram-container"
          style={{
            height: '80%',
          }}
          ref={this.containerRef}
        ></div>
      </Box>
    );
  }
}
