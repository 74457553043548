import React, { useEffect } from 'react';
import RollunLogo from '../../../UI/RollunLogo';
import LoginButton from './LoginButton';
import { Box, Typography, useTheme } from '@material-ui/core';
import LoginForm from './LoginForm';
import LoginMessageDisplayer from './LoginMessageDisplayer';
import { getUserIdentity } from '../../../utils/common.utils';
import { useHistory } from 'react-router-dom';
import { BEFORE_LOGIN_PATH_KEY } from '../../../UI/ErrorView';
import { USER_UNAUTHORIZED } from '../../Table/util/constants';

const Login = ({}) => {
  const theme = useTheme();
  const { push } = useHistory();

  useEffect(() => {
    localStorage.setItem(USER_UNAUTHORIZED, 'false');

    const checkAuthorization = () => {
      if (localStorage.getItem(USER_UNAUTHORIZED) !== 'false') return;

      getUserIdentity(false).then((userIdentity) => {
        if (
          userIdentity.user &&
          userIdentity.role &&
          userIdentity.role !== 'guest'
        ) {
          const sessionRoute = sessionStorage.getItem(BEFORE_LOGIN_PATH_KEY);
          if (!sessionRoute) return;
          push(sessionRoute);
        }
      });
    };

    checkAuthorization();

    const intervalId = setInterval(checkAuthorization, 30000);

    return () => clearInterval(intervalId);
  }, [push]);

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      style={{ backgroundColor: 'white' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="350px"
        style={{ gap: theme.spacing(2) }}
      >
        <RollunLogo width={200} />
        <LoginMessageDisplayer />
        <LoginForm />
        <LoginButton
          name="Login with Google"
          href="/oauth/redirect?action=login"
          color="primary"
        />
        <LoginButton
          name="Register with Google"
          href="/oauth/redirect?action=register"
          color="success"
        />
        <Typography variant="body1" color="textSecondary">
          Rollun LC &copy; 2014-{new Date().getFullYear()}
        </Typography>
      </Box>
    </Box>
  );
};

export default Login;
