import React, { useState } from 'react';
import {
  AbstractArrayNode,
  AbstractQueryNode,
  AbstractScalarNode,
} from 'rollun-ts-rql';
import Select from 'react-select';
import { Button, Card, HiddenInput } from '../../../../../UI';
import AbstractBinaryNode from 'rollun-ts-rql/dist/nodes/binaryNodes/AbstractBinaryNode';
import rqlNodeFactory from '../../../../../utils/RqlNodeFactory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { castValueForSearch } from '../../../util';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Tooltip as MuiTooltip } from '@material-ui/core';

interface IProps {
  path: Array<number>;
  node: AbstractScalarNode | AbstractArrayNode;
  fieldNames: Array<string>;
  onNodeRemove: (path: Array<number>) => void;
  onNodeChange: (path: Array<number>, newNode: AbstractQueryNode) => void;
}

export interface INodeEditorProps<NodeType> {
  node: NodeType;
  path: Array<number>;

  onNodeRemove: (path: Array<number>) => void;
  onNodeChange: (path: Array<number>, newNode: AbstractQueryNode) => void;
}

// const SCALAR_NODES_NAMES: { [key: string]: string } = {
//   eq: 'Равно (учитывая регистр)',
//   alike: 'Равно (регистр не важен)',
//   contains: 'Содержит (регистр не важен)',
//
//   in__comma_separator: 'Включить значения (через запятую)',
//   in__break_line_separator: 'Включить значения (через разрыв строки)',
//   in: 'Включить значения',
//
//   // eq: 'Равно',
//   gt: 'Больше',
//   ge: 'Больше или равно',
//   lt: 'Меньше',
//   le: 'Меньше иии равно',
//
//   eqn: 'null',
//   empty: 'Пусто или null',
//
//   eqt: 'TRUE',
//   eqf: 'FALSE',
// };

const SCALAR_NODES_NAMES: { [key: string]: string } = {
  eq: 'Equal (case-sensitive)',
  alike: 'Equal (case-insensitive)',
  contains: 'Contains (case-insensitive)',

  // in__comma_separator: 'Включить значения (через запятую)',
  // in__break_line_separator: 'Включить значения (через разрыв строки)',
  in: 'Include values',

  // eq: 'Равно',
  gt: 'Greater',
  ge: 'Greater than or equal',
  lt: 'Less',
  le: 'Less than or equal',

  eqn: 'Null',
  empty: 'Empty or null',

  eqt: 'TRUE',
  eqf: 'FALSE',
};

const getNodeValue = (
  node: AbstractScalarNode | AbstractBinaryNode | AbstractArrayNode,
): string => {
  if (node instanceof AbstractScalarNode) {
    return node.value === null ? 'null()' : node.value.toString();
  }
  if (node instanceof AbstractArrayNode) {
    return node.values.join(',');
  }
  return '';
};

const getInputFormat = (input: string): string => {
  // false means 'in__break_line_separator' this scalar node name
  // true means 'in' this scalar node name
  return input.split('\n').length > 1 ? 'in__break_line_separator' : 'in';
};

let name = '';

const NonLogicalNodeEditor: React.FC<IProps> = ({
  node,
  onNodeChange,
  path,
  onNodeRemove,
  fieldNames,
}: IProps) => {
  const nodeValue = getNodeValue(node);
  const [nodeName, setNodeName] = useState(node.name);
  const [asNumber, setAsNumber] = useState(false);
  const [checked, setChecked] = useState(getInputFormat(nodeValue) === 'in');
  const [currentNodeValue, setCurrentNodeValue] = useState(nodeValue);

  const pathOfNonLogicalNode = path;

  const handleSubmit = (str: string, asNumber?: boolean) => {
    if (nodeName === 'in' || nodeName === 'in__break_line_separator') {
      name = getInputFormat(str);
      setNodeName(name);
      setChecked(name === 'in');
    } else {
      name = nodeName;
    }

    onNodeChange(
      pathOfNonLogicalNode,
      rqlNodeFactory(name, {
        field: node.field,
        value: castValueForSearch(str, { asNumber }),
      }),
    );
  };

  return (
    <Card className="d-flex flew-row">
      <Select
        menuPlacement="top"
        onChange={(value: any) => {
          onNodeChange(
            pathOfNonLogicalNode,
            rqlNodeFactory(nodeName, {
              field: value.value,
              value: (node instanceof AbstractArrayNode && node.values) || [],
            }),
          );
        }}
        className="ml-2 query-node-selector mt-1"
        value={{
          value: node.field,
          label: node.field,
        }}
        options={fieldNames.map((name) => ({
          value: name,
          label: name,
        }))}
      />
      <Select
        menuPlacement="top"
        onChange={(value: any) => {
          let name = value.value;

          if (name === 'in' || name === 'in__break_line_separator') {
            name = getInputFormat(nodeValue);
            setNodeName(name);
          } else {
            setNodeName(value.value);
          }

          onNodeChange(
            pathOfNonLogicalNode,
            rqlNodeFactory(name, {
              field: node.field,
              value: nodeValue,
            }),
          );
        }}
        className="ml-2 query-node-selector mt-1"
        value={{
          value: nodeName,
          label: SCALAR_NODES_NAMES[node.name],
        }}
        options={Object.entries(SCALAR_NODES_NAMES).map(
          ([name, description]) => ({
            value: name,
            label: description,
          }),
        )}
      />
      {nodeName === 'in' || nodeName === 'in__break_line_separator' ? (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="button-tooltip-2">
              {checked
                ? 'Включить значения (через запятую)'
                : 'Включить значения (через разрыв строки'}
            </Tooltip>
          }
        >
          <div style={{ margin: '10px 10px 10px 10px' }}>
            <input
              style={{ WebkitTransform: 'scale(2)' }}
              type="checkbox"
              checked={true}
              onChange={(e: any) => {
                // setChecked(e.target.checked);
                setNodeName(getInputFormat(nodeValue));
                alert('This functionality temporary dont work');
              }}
            />
          </div>
        </OverlayTrigger>
      ) : null}
      {['eq', 'like', 'alike'].some((node) => node === nodeName) ? (
        <MuiTooltip
          id="button-tooltip-2"
          title={
            asNumber ? 'Interpret as number' : 'Interpret as string, default'
          }
        >
          <div style={{ margin: '10px 10px 10px 10px' }}>
            <input
              style={{ WebkitTransform: 'scale(2)' }}
              type="checkbox"
              checked={asNumber}
              onChange={(e: any) => {
                setAsNumber(e.target.checked);
                handleSubmit(currentNodeValue, e.target.checked);
              }}
            />
          </div>
        </MuiTooltip>
      ) : null}
      {!(node instanceof AbstractBinaryNode) && (
        <HiddenInput
          type="text"
          placeholder="Enter your search value..."
          inputClassName="ml-2 my-1 query-node-input"
          value={nodeValue}
          as={node instanceof AbstractArrayNode ? 'textarea' : 'input'}
          containerClassName="ml-2"
          onSubmit={(str) => {
            setCurrentNodeValue(str);
            handleSubmit(str, asNumber);
          }}
        />
      )}
      <Button
        onClick={() => {
          onNodeRemove(pathOfNonLogicalNode);
        }}
        color="danger"
        size="sm"
        className="m-2"
      >
        <FontAwesomeIcon icon="times" />
      </Button>
    </Card>
  );
};

export default NonLogicalNodeEditor;
