import React, { Component } from 'react';
import { IEditorProps, IEditorState } from '../EditService';
import HttpDatastore from 'rollun-ts-datastore/dist';
import { FrontConfigRow } from '../../../../RootApp/Root.app';
import { AppConfig } from '../../../../AbstractService';
import { Button } from '../../../../../UI';
import { JsonEditor as JSONInput } from 'json-edit-react';

class DefaultEditor extends Component<IEditorProps, IEditorState> {
  servicesConfigDS = new HttpDatastore<FrontConfigRow>(
    '/api/datastore/frontConfigDataStore',
  );

  handleConfigSave = (newConfig: AppConfig) => {
    this.setState({ isLoading: true, isError: false });
    this.servicesConfigDS
      .update({ id: this.props.serviceID, config: newConfig })
      .catch(() => this.setState({ isError: true }))
      .finally(() => this.setState({ isLoading: false }));
  };

  constructor(props: IEditorProps) {
    super(props);
    this.state = {
      config: props.config,
      isLoading: false,
      isError: false,
    };
  }

  render() {
    const { isLoading, isError, config } = this.state;
    return (
      <div>
        <div className="d-flex my-2 justify-content-between">
          <h3>Table edit</h3>
          <Button
            color={isError ? 'danger' : 'primary'}
            disabled={isLoading}
            onClick={() => this.handleConfigSave(config)}
          >
            Save
          </Button>
        </div>
        <JSONInput
          data={config}
          onUpdate={({ newData }) => {
            if (newData) {
              this.setState({ config: newData as AppConfig });
            }
          }}
        />
      </div>
    );
  }
}

export default DefaultEditor;
